import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'

const ImageCarousel = ({ images, className, interation, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleLeftClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex - 1 + images.length) % images.length)
    }
  }

  const handleRightClick = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex((currentIndex + 1) % images.length)
    }
  }

  const handleDotClick = (index) => {
    setCurrentIndex(index)
  }

  return (
    <div className="h-[70vh] xs:h-[60vh] md:h-[82vh] flex items-center justify-center">
      <div className="flex flex-col items-center p-2 bg-[#69196d] rounded-md w-full max-w-4xl h-full">
        <div className='relative flex-grow overflow-hidden w-full' style={{ maxHeight: 'calc(100% - 100px)' }}>
          {currentIndex > 0 && (
            <button
              onClick={handleLeftClick}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 p-2 rounded-full z-10"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          )}
          {currentIndex < images.length - 1 && (
            <button
              onClick={handleRightClick}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 p-2 rounded-full z-10"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          )}
          <img
            src={images[currentIndex]}
            className="w-full h-full object-contain"
            alt="carousel image"
          />
          {images.length > 1 && (
            <div className="absolute bottom-3 flex left-1/2 -translate-x-1/2">
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`mx-1 h-2 w-2 rounded-full ${currentIndex === index ? 'bg-white' : 'bg-gray-300'}`}
                />
              ))}
            </div>
          )}
        </div>
  
        <div className="mt-2 text-white">{interation}</div>
        {title && (
          <div className="w-full rounded-md bg-white/30 text-white mt-3 p-3 font-bold text-sm md:text-md lg:text-lg xl:text-xl">
            {title}
          </div>
        )}
      </div>
    </div>
  )
}

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ImageCarousel

