import { Link } from 'react-scroll';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../../../shad_components/ui/singleCarousel";
import ImageCarousel from './carousel/carousel';
const libraryImages = [
    {
        // title: " ",
        interataction: <div className=" flex justify-center">
            <Link to='career'>
                <button className="styled-button mt-8 md:mt-2"  >
                    Register Now
                    <div className="inner-button">
                        <svg id="Arrow" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" className="icon">
                            <defs>
                                <linearGradient y2="100%" x2="100%" y1="0%" x1="0%" id="iconGradient">
                                    <stop style={{ stopColor: '#FFFFFF', stopOpacity: 1 }} offset="0%" />
                                    <stop style={{ stopColor: '#AAAAAA', stopOpacity: 1 }} offset="100%" />
                                </linearGradient>
                            </defs>
                            <path fill="url(#iconGradient)" d="M4 15a1 1 0 0 0 1 1h19.586l-4.292 4.292a1 1 0 0 0 1.414 1.414l6-6a.99.99 0 0 0 .292-.702V15c0-.13-.026-.26-.078-.382a.99.99 0 0 0-.216-.324l-6-6a1 1 0 0 0-1.414 1.414L24.586 14H5a1 1 0 0 0-1 1z" />
                        </svg>
                    </div>
                </button>
            </Link>
        </div>,
        images: ["/assets/libraryImage/library10.jpg"]
    },

    {
        title: "First official meeting of JOB FIRST...on 31/12/2020 @Hotel Malayoram Gateway Mukkam,Calicut. We decided that Ms Saritha Biju as Managing director, Ms Vijini. P as HR and Ms Sreeja Santhosh as Tele Officer.",
        interataction: "",
        images: ["/assets/libraryImage/library1.jpg", "/assets/libraryImage/library2.jpg"]
    },
    {
        title: "Welcome Ceremony of our 2nd meeting on 13/04/2021@Foodies Restaurant NIT. Calicut... Managing director Ms. Saritha Biju Welcomes Our first staff Ms. Sreeja Santhosh",
        interataction: "",
        images: ["/assets/libraryImage/library3.jpg",]
    },
    {
        title: "3rd meeting onwards(25-09-21@Hotel petra, Thamarassery,Calicut). The challenging blood of JOB FIRST... was deeply rooted in the vein of Malabar Kerala from 3rd meeting onwards(25-09-21@Hotel petra, Thamarassery,Calicut)",
        interataction: "",
        images: ["/assets/libraryImage/library4.jpg"]
    },
    {
        title: "4TH meet on 25-12-2021@Makkani restaurant Omassery,Calicut. JOB FIRST... successfully achieved the 100% business activation in North Kerala.Celebrating our success in 4TH meet on 25-12-2021@Makkani restaurant Omassery,Calicut.",
        interataction: "",
        images: ["/assets/libraryImage/library5.jpg"]
    },
    {
        title: "5TH MEET@HOTEL PARADISE Kunnamangalam,Calicut on 30/04/2022. Conducted 5TH MEET@HOTEL PARADISE Kunnamangalam,Calicut on 30/04/2022.We proudly announced 6 JOB FIRST... AWARDS.",
        interataction: "",
        images: ["/assets/libraryImage/library6.jpg"]
    },
    {
        title: "6 th meeting on 06-08-2022@HOTEL STEAM X Kunnamangalam,Calicut. JOB FIRST...feels very privilaged to announce WOMEN OF THE YEAR AWARD in our 6 th meeting on 06-08-2022@HOTEL STEAM X Kunnamangalam,Calicut",
        interataction: "",
        images: ["/assets/libraryImage/library8.jpg"]
    },
    {
        title: " 7 th meeting on 24/12/2022@Hill side Resort NIT,Calicut. \"Recognition is the greatest motivator\"JOB FIRST... implemented a new business concept \"JET HR \" in 7 th meeting on 24/12/2022@Hill side Resort NIT,Calicut.",
        interataction: "",
        images: ["/assets/libraryImage/library7.jpg"]
    },
    
    {
        title: "JOB FIRST... attended first job fair on 20/03/2023@Koduvally school, Kozhikode",
        interataction: "",
        images: ["/assets/libraryImage/library14.jpg"]
    },

    {
        title: "JOB FIRST...Introduced a tremendous business approach HR TIE UP in 8 th meet on 24/08/2023@ BROAST RESTAURANT NIT, Calicut",
        interataction: "",
        images: ["/assets/libraryImage/library11.jpg"]
    },

    
    {
        title: " Systematic branch visit(SBV) is a different attempt of JOB FIRST... in business world (Participants👉Sreeja santhosh&Salini)",
        interataction: "",
        images: ["/assets/libraryImage/library13.jpg"]
    },

    {
        title: "JOB FIRST...PROFIT MEET FIRST... Celebrating first profit meet on 15-08-2023 connecting with the 9TH official meeting  and training @ LE Candles Premium resort NIT, Calicut. Group MD Smt RADHAMANI. K inaugurated the function",
        interataction: "",
        images: ["/assets/libraryImage/library12.jpg"]
    },
    {
        title: "Periodic progress of JOB FIRST... in media improvisation from PAID PBC to WNLB and now in SOCIAL MEDIA Attending 10TH MEET @90 Degree Restaurant NIT, Calicut on 24-12-2023",
        interataction: "",
        images: ["/assets/libraryImage/library15.jpg"]
    },
    {
        title: "JOB FIRST... proudly announced the 6 AWARDS FIRST in the 11TH Official meeting&Training on 06-04-2024 @ S GRILL RESTAURANT Mukkam, Calicut...👍👍👍",
        interataction: "",
        images: ["/assets/libraryImage/library16.jpg"]
    },
    {
        title: "RADHAMANI GROUP OF COMPANY HEAD SMT RADHAMANI. K  grantly Inaguarated the registered office in the 12TH official meeting&Training on 24-08-2024@NIT, Calicut.",
        interataction: "",
        images: ["/assets/libraryImage/library18.jpg"]
    },
    {
        title: "JOB FIRST... Luanched official website www.jobfirst2020.com on 24-08-24@ 12TH meeting&Training Registered office, NIT, Calicut 👍",
        interataction: "",
        images: ["/assets/libraryImage/library17.jpg"]
    },
    

    
    {
        // title: " ",
        interataction: <div className=" flex justify-center">
            <Link to='career'>
                <button className="styled-button mt-8 md:mt-2"  >
                    Register Now
                    <div className="inner-button">
                        <svg id="Arrow" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" className="icon">
                            <defs>
                                <linearGradient y2="100%" x2="100%" y1="0%" x1="0%" id="iconGradient">
                                    <stop style={{ stopColor: '#FFFFFF', stopOpacity: 1 }} offset="0%" />
                                    <stop style={{ stopColor: '#AAAAAA', stopOpacity: 1 }} offset="100%" />
                                </linearGradient>
                            </defs>
                            <path fill="url(#iconGradient)" d="M4 15a1 1 0 0 0 1 1h19.586l-4.292 4.292a1 1 0 0 0 1.414 1.414l6-6a.99.99 0 0 0 .292-.702V15c0-.13-.026-.26-.078-.382a.99.99 0 0 0-.216-.324l-6-6a1 1 0 0 0-1.414 1.414L24.586 14H5a1 1 0 0 0-1 1z" />
                        </svg>
                    </div>
                </button>
            </Link>
        </div>,
        images: ["/assets/libraryImage/library9.jpg"]
    },

];

export function LibraryCard() {
    return (
        <div className=" flex animate">
            <div className=" w-full flex justify-center px-9 pe-12 ">
                <Carousel className=" max-w-[92vw] bg-[#69196d] rounded-md">
                    <CarouselPrevious />
                    <CarouselNext />
                    <CarouselContent >
                        {libraryImages.map((image, index) => (
                            <CarouselItem key={index}>

                                <div className="p-1 ">
                                    <ImageCarousel title={image.title} images={image.images} interation={image.interataction} />
                                    
                                </div>
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </Carousel>
            </div>
        </div>
    );
}
