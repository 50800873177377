import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { createContext, useRef, useState } from "react";
import { BrowserRouter , Routes , Route, useLocation, Navigate } from "react-router-dom";
import HomePage from "./Pages/Users/Home";
import Login from "./Pages/Users/Login";






gsap.registerPlugin(ScrollTrigger)
export const GlobelContext = createContext();

function App() {
  const scrollBarRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('home');
  const [rebuildlocations, setRebuildlocations] = useState(false);

  const setSelectedtab = (tab) => {
    setSelectedTab(tab);
  };

  const rebuildLocationfn = () => {
    setRebuildlocations(!rebuildlocations);
  };

  return (
     <div ref={scrollBarRef} id='scrollableBar'>
    <GlobelContext.Provider value={{
      scrollBarRef,
      selectedTab: selectedTab,
      setSelectedtab,
      rebuildLocationfn,
      rebuildlocations
    }}>
      <BrowserRouter>
        <AppLayout />
      </BrowserRouter>
    </GlobelContext.Provider>
  </div>
  );
}

function AppLayout() {
  const location = useLocation();

  return (
    <>
     <Routes>
     <Route index element={<Navigate to={'/home'} replace />} />
      <Route path="/home" element = {< HomePage />} />
      <Route path="/login" element = {< Login />} />
     </Routes>
    </>
  )
}

export default App;
