import React from 'react';
const teamMembers = [
    {
      name: "Ms. Radhamani K",
      role: "Director of Radhamani group of companies",
      category: "Management Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Saritha Biju",
      role: "MD of JOB FIRST...",
      category: "Management Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Soumya K",
      role: "Media Officer",
      category: "Management Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Sithara K T",
      role: "Tele Officer",
      category: "Management Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Sreeja Santhosh",
      role: "Senior Tele Officer & HR",
      category: "Official Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Renu",
      role: "Tele Officer & HR",
      category: "Official Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Salini K",
      role: "Tele Officer & HR",
      category: "Official Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Seethalakshmi",
      role: "Tele Officer",
      category: "Official Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Saritha M",
      role: "Tele Officer",
      category: "Official Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
    {
      name: "Ms. Sunaina",
      role: "Tele Officer",
      category: "Official Teams",
      image: "/assets/teams/ladies.png", // Sample image URL
    },
  ];
  
export const TeamComponent = () => {
  return (
    <div className="animate min-h-screen py-10">
      <div className="container mx-auto dm-serif-text-regular">
        <div className="mb-6">
          <h2 className="text-2xl font-semibold">Management Teams</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
            {teamMembers
              .filter((member) => member.category === "Management Teams")
              .map((member, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md text-center"
                >
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-24 h-24 rounded-full mx-auto mb-4 border shadow-md"
                  />
                  <h3 className="text-xl font-bold">{member.name}</h3>
                  <p className="text-gray-600">{member.role}</p>
                </div>
              ))}
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-semibold">Official Teams</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
            {teamMembers
              .filter((member) => member.category === "Official Teams")
              .map((member, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md text-center"
                >
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-24 h-24 rounded-full mx-auto mb-4 border shadow-md"
                  />
                  <h3 className="text-xl font-bold">{member.name}</h3>
                  <p className="text-gray-600">{member.role}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
