import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import './Home.css';
// import image1 from '../../assets/job1.jpg';
// import image2 from '../../assets/job2.jpg';
// import image3 from '../../assets/job3.jpg';
// import image4 from '../../assets/jobfest.jpg'
import { useNavigate } from 'react-router-dom';
import { CareerForm } from './careerForm';
import { LibraryCard } from './library/libraryCard';
import ContactUs from './contactUs/contactUs';
import { TeamComponent } from './teams/teams';

export const styles = {
  sectionHome: {
    minHeight: '100vh',
    width: '100%',
    backgroundImage: `url(${'/assets/jobfest.jpg'})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflowX: 'hidden',
    padding: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflowX: 'hidden'

  },
  sectionAbout: {
    minHeight: '100vh',
    backgroundColor: '#842b8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    overflowX: 'hidden'

  },
  sectionLibrary: {
    minHeight: '100vh',
    backgroundColor: '#C0C78C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    overflowX: 'hidden'

  },
  sectionCareer: {
    minHeight: '100vh',
    backgroundColor: '#842b8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '70px 20px 70px 20px',
    overflowX: 'hidden'

  },
  sectionContact: {
    minHeight: '100vh',
    backgroundColor: '#b0b0b0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    overflowX: 'hidden'

  },
  heading: {
    fontSize: '5vw',
    color: '#333',
    textAlign: 'center',
  },
  text: {
    fontSize: '20px',
    color: '#666',
    textAlign: 'center',
  },

};

function Home() {
  const navigate = useNavigate();
  const sectionsRef = useRef([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };


  useEffect(() => {
    const sections = sectionsRef.current;

    const homeSection = sections[0];
    setTimeout(() => {
      if (homeSection) {
        homeSection.classList.add('show_animate');
      }
    }, 200); // 0.2s delay






    const handleScroll = () => {
      const top = window.scrollY;
      sections.forEach((sec, index) => {
        const offset = sec.offsetTop - 350;
        const height = sec.offsetHeight;


        if (top >= offset && top < offset + height) {
          // setActiveSection(index);
          sec.classList.add('show_animate');
        } else {
          sec.classList.remove('show_animate');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div >
      <div style={{ position: 'absolute' }} className=''>
        <nav style={{
          backgroundColor: '#5f0b66',
          background: 'linear-gradient(90deg, #5f0b66, #843D7F)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          color: 'white',
          zIndex: 1000
        }} className="border-gray-200 bg-gray-50 navsbars">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <div className=' flex gap-1'>
              <img src="/assets/logos/logoSmallWhite.png" alt="Flowbite Logo" className="h-10 w-10" />
              <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse ml-2">
                <span className="self-center text-3xl sm:text-4xl roboto !font-bold whitespace-nowrap text-black dark:text-black ">JOB FIRST...</span>
              </a>
            </div>
            <button
              onClick={toggleMenu}
              data-collapse-toggle="navbar-solid-bg"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-solid-bg"
              aria-expanded="false"
            >
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>
            <div style={{ fontFamily: '' }} className={`${isMenuOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-solid-bg">
              <ul style={{ cursor: 'pointer', color: 'white' }} className="flex flex-col font-medium mt-4 rounded-lg bg-black-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                <li>
                  <Link onClick={handleLinkClick} to="home" smooth={true} duration={500} className="grey-qo-regular block py-2 px-3 md:p-0 text-white-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-300 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ml-4">
                    Home
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to="about" smooth={true} duration={500} className="grey-qo-regular block py-2 px-3 md:p-0 text-white-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-300 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ml-4">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to="library" smooth={true} duration={500} className="grey-qo-regular block  py-2 px-3 md:p-0 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-300 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ml-4">
                    Gallary
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to="career" smooth={true} duration={500} className="grey-qo-regular block py-2 px-3 md:p-0 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-300 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ml-4">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to="team" smooth={true} duration={500} className="grey-qo-regular block py-2 px-3 md:p-0 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-300 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ml-4">
                    Teams
                  </Link>
                </li>
                <li>
                  <Link onClick={handleLinkClick} to="contact" smooth={true} duration={500} className="grey-qo-regular block py-2 px-3 md:p-0 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-300 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent ml-4">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>



      {/* Home Section */}
      <section
        ref={(el) => (sectionsRef.current[0] = el)}
        className="sec1"
        name="home"
        style={styles.sectionHome}
      >
        <div className=' text-center'>
          <h1 style={{ color: 'black', fontFamily: '', fontSize: '111px', fontWeight: '400', marginTop: '30px' }} className="ml-10 mt-3 animate roboto jobsfirst job">JOB </h1>
          <h1 style={{ color: 'black', fontFamily: '', fontSize: '111px', fontWeight: '400', marginTop: '-45px' }} className=" font-bold animate roboto jobsfirst first">FIRST... </h1>
          <p style={{ color: 'white', fontSize: '48px', fontFamily: 'comfortaa', fontWeight: 'bold', marginTop: '-45px' }} className=" animate bankassoci ml-20">Bank associated job partner</p>
          <p className=' animate text-right roboto !font-semibold text-md sm:text-xl md:text-2xl'>Calicut, Kerala</p>
        </div>
        {/* <button  className=" animate bankassoci ml-20">Register</button> */}
        <Link to='career'>
          <button className="styled-button mt-8 md:mt-2"  >
            Register Now
            <div className="inner-button">
              <svg id="Arrow" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" className="icon">
                <defs>
                  <linearGradient y2="100%" x2="100%" y1="0%" x1="0%" id="iconGradient">
                    <stop style={{ stopColor: '#FFFFFF', stopOpacity: 1 }} offset="0%" />
                    <stop style={{ stopColor: '#AAAAAA', stopOpacity: 1 }} offset="100%" />
                  </linearGradient>
                </defs>
                <path fill="url(#iconGradient)" d="M4 15a1 1 0 0 0 1 1h19.586l-4.292 4.292a1 1 0 0 0 1.414 1.414l6-6a.99.99 0 0 0 .292-.702V15c0-.13-.026-.26-.078-.382a.99.99 0 0 0-.216-.324l-6-6a1 1 0 0 0-1.414 1.414L24.586 14H5a1 1 0 0 0-1 1z" />
              </svg>
            </div>
          </button>
        </Link>




      </section>

      {/* About Us Section */}
      <section
        ref={(el) => (sectionsRef.current[1] = el)}
        className='sec2'
        name="about"
        style={styles.sectionAbout}
      >
        <h1 className='animate heading aboutus' style={styles.heading}>About Us</h1>
        <div className='m-3 max-w-3xl' style={{ fontFamily: 'fantasy' }}>
          <p className='animate bank_partner  ' style={styles.text}>
            <strong>JOB FIRST...</strong> started its journey on October 2019. JOB FIRST... is an online job recruitment institution based in Calicut,Kerala. We have highly talented women oriented working panel as a part of women empowerment.Our organization offers the latest bank jobs and bank associated vacancies in Kerala.
          </p>
          <p className='animate bank_partner  mt-1' style={styles.text}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>JOB FIRST...</strong> is a unique platform that guides you to a better future. Our quality and services have helped lots of job seekers build a bright care
          </p>
        </div>
      </section>

      {/* Library Section */}
      <section
        ref={(el) => (sectionsRef.current[2] = el)}
        name="library"
        className='sec3'
        style={styles.sectionLibrary}
      >

        {/* <h1 className='animate heading library px-1' style={styles.heading}>Library</h1> */}
        <LibraryCard />
        {/* <p className='animate bank_partner text-2xl font-bold overflow-hidden py-3' >
          Page under maintenance !!!
        </p> */}
        {/* <h1 className='animate heading library' style={styles.heading}>Library</h1>
        <p className='animate bank_partner' style={styles.text}>
          Explore our vast collection of resources to find the information you need.
        </p>
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} className='mt-5 animate'>

            <img style={{ width: '300px', height: '300px', '--i': '1', margin: '10px' }} src={'/assets/job1.jpg'} class="h-8" alt="Flowbite Logo" />


            <img style={{ width: '300px', height: '300px', '--i': '1', margin: '10px' }} src={'/assets/job2.jpg'} className='animate ' alt='Job age' />
            <img
              className='animate  '
              style={{ width: '300px', height: '300px', '--i': '2', margin: '10px' }}
              src={'/assets/job3.jpg'}
              alt='Job Ige'
            />
          </div>
        </div> */}
      </section>

      {/* Careers Section */}
      <section
        ref={(el) => (sectionsRef.current[3] = el)}
        className='sec4 '
        name="career"
        style={styles.sectionCareer}
      >
        <h1 className='animate heading careershead library' style={styles.heading}>Careers</h1>
        <p className='animate bank_partner' style={styles.text}>
          Discover the opportunities we have to offer and start your career journey with us.
        </p>

        {/* CAREER FORM */}
        <CareerForm />
      </section>

      {/* Team Section */}
      <section
        ref={(el) => (sectionsRef.current[4] = el)}
        className='sec5'
        name="team"
        style={{
          backgroundColor: '#F1D3CE',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px',
          overflowX: 'hidden'
        }}
      >
        <h1 className='animate heading library px-3 overflow-hidden' style={styles.heading}>Team</h1>
        {/* <p className='animate bank_partner text-2xl font-bold overflow-hidden py-3' >
          Page under maintenance !!!
        </p> */}
        <TeamComponent />
      </section>

      {/* Contact Section */}
      <section
        ref={(el) => (sectionsRef.current[5] = el)}
        className='sec6'
        name="contact"
        style={styles.sectionContact}
      >
        <ContactUs />

      </section>
    </div>
  );
}

export default Home;